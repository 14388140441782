<template>
    <div>
        <b-card class="blockchain-detail-card">
            <b-img
                class="header-image"
                :src="is_left ? require('@/assets/images/banners/gulfCoin.gif') : require('@/assets/images/banners/TresLechesToken.gif')"
            />
            <div class="mt-4">
                <h2>{{topic}}</h2>
            </div>
            <div class="mt-4">
                <span class="mt-4">{{description}}</span>
            </div>
            <div class="mt-4">
                <h4 class="mt-4">Number of Complete Projects: {{complete_projects}}</h4>
            </div>
            <div class="mt-4">
                <h1 class="mt-4">{{price}} Onwards</h1>
            </div>
        </b-card>
    </div>
</template>

<script>

import {
    BCard,
    BImg
} from "bootstrap-vue";

export default {
    props: ['topic', 'description', 'complete_projects', 'price'],
    components: {
        BCard,
        BImg
    }
}
</script>

<style>
    .blockchain-detail-card {
        background: #070859 !important;
        border-radius: 1px;
    }
    .blockchain-detail-card:hover {
        background: #393AF8 !important;
    }
    .header-image {
        width: 100% !important;
        height: 200px !important;
    }
</style>