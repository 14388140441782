<template>
    <div>
        <div>
            <b-button variant="primary" @click="$router.go(-1)" size="sm">
                <feather-icon
                    icon="ArrowLeftIcon"
                    class="mr-50"
                />
                <span class="align-middle">BACK</span>
            </b-button>
        </div>
        <div class="topics-details">
            <feather-icon 
                icon="CodeIcon"
                size="35"
            />
            <h1 class="mt-2">Development </h1> 
            <span>
                Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.
            </span>
        </div>
        <div class="d-flex mt-4">
            <h2>Blockchain Development</h2>
        </div>
        <b-row class="mt-2">
            <b-col md="6">
                <blockchain-details
                    topic='Smart Contract Development'
                    description='Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.'
                    complete_projects='200'
                    price='$2500'
                />
            </b-col>
            <b-col md="6">
                <blockchain-details
                    topic='Dapp Development'
                    description='Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular.'
                    complete_projects='100'
                    price='$1250'
                />
            </b-col>
        </b-row>
        <topic-n-details 
            topic="Web Development"
            description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
        />
        <b-row>
            <div class="d-flex align-items-center justify-content-center mt-1 col-12">
                <b-col md="8">
                    <b-card class="banner-detail-card mt-3">
                        <div class="d-flex mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex  mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="price-details mt-4">
                            <h1 class="mt-2">$800 Onwards</h1> 
                        </div>
                    </b-card>
                </b-col>
            </div>
        </b-row>

        <topic-n-details 
            topic="Mobile App/ Systems/IoT or any software solutions"
            description="Old family photos undoubtedly inspire a flood of memories. Nostalgia can bring back the very moment the photos were taken, for better or worse. A few fun families recreated old photos and the results are spectacular."
        />
        <b-row>
            <div class="d-flex align-items-center justify-content-center mt-1 col-12">
                <b-col md="8">
                    <b-card class="banner-detail-card mt-3">
                        <div class="d-flex mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex  mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex mb-1">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="d-flex">
                            <feather-icon 
                                icon="CheckCircleIcon"
                                size="15"
                                class="mr-50"
                            />
                            <span>Old family photos undoubtedly inspire a flood of memories.</span>
                        </div>
                        <div class="price-details mt-4 ">
                            <h1 class="mt-2">$2500 Onwards</h1> 
                        </div>
                    </b-card>
                </b-col>
            </div>
        </b-row>
    </div>
</template>

<script>

import {
  BRow,
  BCol,
  BCard,
//   BImg,
  BButton
} from "bootstrap-vue";
import BlockchainDetails from './BlockcainDetails.vue'
import TopicNDetails from './TopicNDetails.vue'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BlockchainDetails,
        TopicNDetails,
        BCard
    }
    
}
</script>

<style>
    .topics-details {
        text-align: center;
        padding: 1px 200px;
    }
    .discount-text {
        margin-top: 5px;
    }
    @media (max-width: 1024px) {
        .topics-details {
            padding: 0px !important;
        }
    }
    .banner-detail-card {
        background: #070859 !important;
        padding: 50px 5px;
        border-radius: 1px !important;
    }
    .banner-detail-card:hover {
        background: #393AF8 !important;
    }
    .price-details {
        text-align: center;
        font-weight: bolder;
    }
</style>