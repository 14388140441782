<template>
    <div>
        <div class="d-flex mt-4">
            <h2>{{topic}}</h2>
            <h4 class="ml-1 discount-text">{{discounted_price}}</h4>
        </div>
        <span>
            {{description}}
        </span>
    </div>
</template>

<script>
export default {
    props: ['topic', 'discounted_price', 'description']
}
</script>

<style>
    .discount-text {
        margin-top: 5px;
    }
</style>